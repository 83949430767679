import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';

const LS_KEY_TOKEN = "ScratchWarsTurnaje__TOKEN";
const LS_KEY_APPID = "ScratchWarsTurnaje__APPID";
const LS_KEY_EXPIR = "ScratchWarsTurnaje__EXPIR";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private http: HttpClient,
  ) {
    let expir = localStorage.getItem(LS_KEY_EXPIR);
    // @TODO make auth working even when key expires during actual work
    if (expir && moment().isBefore(Number(expir) * 1000)) {
      this.token = localStorage.getItem(LS_KEY_TOKEN) || undefined;
      this.appId = localStorage.getItem(LS_KEY_APPID) || undefined;
    } else {
      localStorage.removeItem(LS_KEY_TOKEN);
      localStorage.removeItem(LS_KEY_APPID);
      localStorage.removeItem(LS_KEY_EXPIR);
    }

    this.isLoggedIn$ = new BehaviorSubject(Boolean(this.token));
  }

  private token?: string;
  private appId?: string;

  isLoggedIn$: BehaviorSubject<boolean>;

  async login(appId: string, pin: string) {
    appId = appId.toLowerCase();
    let response = await this.http.post<{token: string, exp: number}>("https://api-v1.scratchwars.zone/login.php", {appId, pin}).toPromise();
    this.token = response.token;
    this.appId = appId;
    this.isLoggedIn$.next(true);
    try {
      localStorage.setItem(LS_KEY_TOKEN, this.token);
      localStorage.setItem(LS_KEY_APPID, this.appId);
      localStorage.setItem(LS_KEY_EXPIR, String(response.exp));
    } catch {}
  }

  logout() {
    this.token = undefined;
    this.appId = undefined;
    localStorage.removeItem(LS_KEY_TOKEN);
    localStorage.removeItem(LS_KEY_APPID);
    localStorage.removeItem(LS_KEY_EXPIR);
    this.isLoggedIn$.next(false);
  }

  getLoggedIn$() {
    return this.isLoggedIn$.asObservable();
  }

  getAppId() {
    return this.appId;
  }

  fetchWithToken<T>(verb: "GET" | "POST" | "DELETE", url: string, body?: object) {
    let headers: Record<string, string> = {};
    if (this.token) {
      headers.Authorization = `Bearer ${this.token}`;
    }
    return this.http.request<T>(verb, url, {body, headers}).toPromise();
  }
}