import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { LandingComponent } from './landing/landing.component';
import { ProfileComponent } from './profile/profile.component';
import { SignupComponent } from './signup/signup.component';
import { ListComponent } from './list/list.component';
import { TournamentComponent } from './tournament/tournament.component';
import { MonthlyRestrictionComponent } from '../components/monthly-restriction/monthly-restriction.component';
import { SeasonalRestrictionComponent } from 'app/components/seasonal-restriction/seasonal-restriction.component';
import { WorldsRestrictionComponent } from '../components/worlds-restriction/worlds-restriction.component';
import { GoldsTransactionsComponent } from './golds-transactions/golds-transactions.component';
import { GoldsPayoutComponent } from './golds-payout/golds-payout.component';
import { GoldsTransferComponent } from './golds-transfer/golds-transfer.component';
import { ScratchwalkRewardsComponent } from './scratchwalk-rewards/scratchwalk-rewards.component';
import { QrCodeComponent } from 'app/components/qr-code/qr-code.component';
import { QRCodeModule } from 'angularx-qrcode';
import { PrizesComponent } from 'app/components/prizes/prizes.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        RouterModule,
        QRCodeModule,
    ],
    declarations: [
        LandingComponent,
        SignupComponent,
        ProfileComponent,
        ListComponent,
        TournamentComponent,
        MonthlyRestrictionComponent,
        SeasonalRestrictionComponent,
        WorldsRestrictionComponent,
        GoldsTransactionsComponent,
        GoldsPayoutComponent,
        GoldsTransferComponent,
        ScratchwalkRewardsComponent,
        QrCodeComponent,
        PrizesComponent,
    ],
})
export class ExamplesModule { }
