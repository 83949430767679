import {Location} from '@angular/common';
import { Component } from '@angular/core';
import { UserService } from 'app/shared/services/user.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { Tournament } from 'app/new/types/tournament.type';
import { GAME_MAP, GET_COLOR, PLACE_MAP, TYPE2_MAP, REASON_MAP } from '../list/list.component';
import { HttpErrorResponse } from '@angular/common/http';

interface TournamentData {
  tournament: Tournament;
  bonuses: Bonus[];
  signups: number;
  userSignup?: {
    swoName?: string;
    seat: number;
  };
  userResult?: {
    swoName?: string;
    place?: number;
    winsOverOg?: number;
    dropWithoutReward?: boolean;
    disqualified?: boolean;
  };
  isAuthorized?: true;
  isOrganizer?: true;
  participants?: number;
  players?: Array<{
    appId: string;
    swoName?: string;
    seat: number;
    boughtBonuses?: string;
    tournamentCount?: number;
  }>;
  results?: Array<{
    appId: string;
    swoName?: string;
    place?: number;
    winsOverOg?: number;
    dropWithoutReward?: boolean;
    disqualified?: boolean;
  }>;
  hasDiscordId?: boolean;
  hasSwoId?: boolean;
  boughtBonuses?: BonusId[];
  transactions?: Array<{
    amount: number;
    reason: string;
    additionalInfo: string;
  }>;
}

interface Code {
  validUntil: string;
  code: string;
  type: string;
  label: string;
}

export type BonusId = number & {__fk: "bonusId"};

interface Bonus {
  bonusId: BonusId;
  name: string;
  price: number;
  text1: string;
  text2: string;
  limitToBuy: number;
  limitToCancel: number;
}

@Component({
  selector: 'app-tournament',
  templateUrl: './tournament.component.html',
  styleUrls: ['./tournament.component.scss']
})
export class TournamentComponent {
  constructor(
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
  ) {}

  /** @deprecated */
  prizes = 8;

  loggedIn$ = this.userService.getLoggedIn$();

  reload$ = new Subject<void>();

  data$ = this.reload$.pipe(
    startWith(undefined),
    switchMap(() => this.activatedRoute.paramMap),
    map(params => params.get("id")),
    switchMap(async id => {
      this.loading = true;
      try {
        let response = await this.userService.fetchWithToken<TournamentData>("GET", `https://api-v1.scratchwars.zone/tournament.php?tournamentId=${id}`);
        return response;
      } catch (e) {
        console.error("FTA", e);
        alert("Nepovedlo se načíst turnaj :(");
        return undefined;
      } finally {
        this.loading = false;
      }
    }),
  );

  loading = false;

  momentDate(item: Tournament) {
    return moment(item.date);
  }
  momentTime(item: Tournament) {
    return moment(item.time, ["HH:mm"]);
  }

  isQr(c: Code) {
    return c.type.startsWith("qr");
  }

  getColor = GET_COLOR;
  type2Map = TYPE2_MAP;
  gameMap = GAME_MAP;
  placeMap = PLACE_MAP;
  reasonMap = REASON_MAP;

  isStillTime(d: TournamentData) {
    return moment().isBefore(`${d.tournament.date}T${d.tournament.time || '23:59:59'}`);
  }
  isStillTimeForBonus(d: TournamentData, offset: number) {
    return moment().add(offset, 'h').isBefore(`${d.tournament.date}T${d.tournament.time || '23:59:59'}`);
  }
  isTimeForStream(d: TournamentData) {
    return moment().isSameOrAfter(`${d.tournament.date}T${d.tournament.time || '00:00:00'}`) && moment().isSameOrBefore(`${d.tournament.date}T23:59:59`);
  }
  canSignup(d: TournamentData) {
    return !d.isOrganizer && (!d.tournament.seats || d.signups < d.tournament.seats);
  }
  canStandby(d: TournamentData) {
    return !d.isOrganizer && d.tournament.seats && d.tournament.canStandby && d.signups >= d.tournament.seats;
  }
  cantSignupAndStandby(d: TournamentData) {
    return (d.tournament.seats && !d.tournament.canStandby && d.signups >= d.tournament.seats);
  }
  isSignupOrStandby(d: TournamentData) {
    return Boolean(d.userSignup?.seat);
  }
  isSignup(d: TournamentData) {
    return d.userSignup?.seat && (!d.tournament.seats || d.userSignup?.seat <= d.tournament.seats);
  }
  isStandby(d: TournamentData) {
    return d.userSignup?.seat && d.tournament.seats && d.userSignup?.seat > d.tournament.seats;
  }

  missesDiscordId(d: TournamentData) {
    return "hasDiscordId" in d && !d.hasDiscordId;
  }
  missesSwoId(d: TournamentData) {
    return "hasSwoId" in d && !d.hasSwoId;
  }

  goBack() {
    this.location.back();
  }

  async signup(d: TournamentData) {
    this.loading = true;
    try {
      await this.userService.fetchWithToken<void>("POST", `https://api-v1.scratchwars.zone/tournament-register.php`, {tournamentId: d.tournament.tournamentId});
      this.reload$.next();
    } catch (e) {
      if (e instanceof HttpErrorResponse && e.status === 402) {
        alert("Nemáš dostatek nominací k přihlášení :( Mrkni do profilu, jak jsi na tom.");
      } else {
        console.error("FTB", e);
        alert("Nepovedlo se přihlásit na akci :(");
      }
    } finally {
      this.loading = false;
    }
  }
  async signoff(d: TournamentData) {
    if (d.boughtBonuses && d.boughtBonuses.length > 0) {
      alert("Pokud se chceš odhlásit z akce, musíš nejprve zrušit všechny své bonusy. Pokud už zrušit nejdou, nemůžeš se odhlásit :(");
    } else {
      this.loading = true;
      try {
        await this.userService.fetchWithToken<void>("POST", `https://api-v1.scratchwars.zone/tournament-unregister.php`, {tournamentId: d.tournament.tournamentId});
        this.reload$.next();
      } catch (e) {
        if (e instanceof HttpErrorResponse && e.status === 403) {
          alert("Z této akce se nemůžeš sám/sama odhlásit :(");
        } else {
          console.error("FTC", e);
          alert("Nepovedlo se odhlásit z akce :(");
        }
      } finally {
        this.loading = false;
      }
    }
  }

  async buyBonus(d: TournamentData, b: Bonus) {
    this.loading = true;
    try {
      await this.userService.fetchWithToken<void>("POST", `https://api-v1.scratchwars.zone/tournament-bonus-buy.php`, {tournamentId: d.tournament.tournamentId, bonusId: b.bonusId});
      this.reload$.next();
    } catch (e) {
      if (e instanceof HttpErrorResponse && e.status === 402) {
        alert("Nemáš dostatek nominací ke koupi bonusu :( Mrkni do profilu, jak jsi na tom.");
      } else if (e instanceof HttpErrorResponse && e.status === 410) {
        alert("Bonus už teď nejde koupit :(");
      } else {
        console.error("BBE", e);
        alert("Nepovedlo se koupit bonus :(");
      }
    } finally {
      this.loading = false;
    }
  }
  async cancelBonus(d: TournamentData, b: Bonus) {
    this.loading = true;
    try {
      await this.userService.fetchWithToken<void>("POST", `https://api-v1.scratchwars.zone/tournament-bonus-cancel.php`, {tournamentId: d.tournament.tournamentId, bonusId: b.bonusId});
      this.reload$.next();
    } catch (e) {
      if (e instanceof HttpErrorResponse && e.status === 410) {
        alert("Bonus už teď nejde zrušit :(");
      } else {
        console.error("CBE", e);
        alert("Nepovedlo se koupit bonus :(");
      }
    } finally {
      this.loading = false;
    }
  }
}
