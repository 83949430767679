import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouterService {
  constructor(
    private router: Router,
  ) {}

  private prevRoute?: string;

  goToLoginPage() {
    if (this.router.url !== "/prihlaseni") {
      this.prevRoute = this.router.url;
      this.router.navigate(["/prihlaseni"]);
    }
  }

  goBackFromLoginPage() {
    if (this.prevRoute) {
      this.router.navigate([this.prevRoute], {replaceUrl: true});
      this.prevRoute = undefined;
    } else {
      this.router.navigate(['/'], {replaceUrl: true});
    }
  }
}