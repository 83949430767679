import { Component, Input } from '@angular/core';
import * as moment from 'moment';
import { Tournament } from '../../new/types/tournament.type';

const monthNames = {
  "01": "Leden",
  "02": "Únor",
  "03": "Březen",
  "04": "Duben",
  "05": "Květen",
  "06": "Červen",
  "07": "Červenec",
  "08": "Srpen",
  "09": "Září",
  "10": "Říjen",
  "11": "Listopad",
  "12": "Prosinec",
};

@Component({
  selector: 'app-worlds-restriction',
  templateUrl: './worlds-restriction.component.html',
  styleUrls: ['./worlds-restriction.component.css']
})
export class WorldsRestrictionComponent {
  @Input() set tournament(tournament: Tournament) {
    this.tournamentIn = tournament;

    let year = Number(moment(tournament.date).format('YYYY'));
    if (year === 2021) {
      this.months = ['2021-01', '2021-02', '2021-03', '2021-04', '2021-05', '2021-06', '2021-07', '2021-08', '2021-09', '2021-10', '2021-11', '2021-12'];
    } else if (year >= 2022) {
      this.months = [`${year-1}-06`, `${year-1}-07`, `${year-1}-08`, `${year-1}-09`, `${year-1}-10`, `${year-1}-11`, `${year-1}-12`, `${year}-01`, `${year}-02`, `${year}-03`, `${year}-04`, `${year}-05`];
    } else {
      this.months = [];
    }
  }
  months?: string[];
  tournamentIn?: Tournament;

  getMonthName(month: string) {
    return monthNames[month.match(/(\d+)$/)![0] as keyof typeof monthNames];
  }
}
