import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RouterService } from 'app/new/services/router.service';
import { UserService } from 'app/shared/services/user.service';

@Component({
  selector: 'app-modal-content',
  template: `
  <div class="modal-header">
    <h5 class="modal-title text-center">Jak se přihlásit?</h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Do <em>Sekce hráče</em> se můžeš přihlásit pomocí appky. Podívej se, jak na to:<p>
    <ol>
      <li>Tapni vpravo nahoře na svůj avatar.</li>
      <li>Nahoře vyber Profil.</li>
      <li>Nahoře vidíš své App ID &ndash; to je to se čtyřmi číslicemi na konci. Tapni na něj.</li>
      <li>Objeví se opět tvé App ID a pod ním tvůj PIN.</li>
      <li>App ID i PIN zadej tady do formuláře a můžeš se přihlásit.</li>
    </ol>
  </div>
  <div class="modal-footer">
    <div class="left-side">
      <button type="button" class="btn btn-default btn-link" (click)="activeModal.close('Close click')">Rozumím</button>
    </div>
  </div>
  `
})
export class SignupModalContent {
  constructor(public activeModal: NgbActiveModal) {}
}

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent {
  test : Date = new Date();
  focus: any;
  focus1: any;

  loading = false;

  loginForm = this.fb.group({
    appId: ['', [Validators.required, Validators.minLength(5), Validators.pattern(/^[a-zA-Z0-9-]+-\d{4}\s*$/)]],
    pin: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern(/^\d{6}$/)]],
  });

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private routerService: RouterService,
    private userService: UserService,
  ) {}

  openHelp() {
    const modalRef = this.modalService.open(SignupModalContent);
    modalRef.componentInstance.name = 'World';
  }

  async submit() {
    if (!this.loginForm.controls.appId.valid) return alert("App ID není správně zapsané, může obsahovat jen písmena, čísla a pomlčky a na konci musí být čtyři čísla.");
    if (!this.loginForm.controls.pin.valid) return alert("PIN není správně zapsaný, jedná se vždy o šest číslic.");

    let {appId, pin} = this.loginForm.value;
    appId = appId.replace(/\s+$/, "");
    this.loading = true;
    try {
      await this.userService.login(appId, pin);
      this.routerService.goBackFromLoginPage();
    } catch (e) {
      if (e && e.status === 401) alert("Nepodařilo se přihlásit, App ID neexistuje nebo PIN není správný.");
      else alert("Bohužel došlo k chybě při přihlašování, zkusíme to brzy opravit…");
    } finally {
      this.loading = false;
    }
  }
}
