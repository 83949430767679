import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Tournament, TournamentId } from 'app/new/types/tournament.type';
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { UserService } from '../../shared/services/user.service';

import { GET_COLOR, TYPE2_MAP, GAME_MAP, PLACE_MAP, REASON_MAP } from '../list/list.component';


interface ScratchwalkCheckbox {
  point: number;
  timestamp: string;
}


interface ScratchwalkCode {
  point: number;
  code: string;
  type: string;
  validUntil: string;
}


interface Achievement {
  achievementId: number;
  name: string;
  description: string;
  points: number;
  repeating: 'never' | 'oncePerWidth' | 'indefinitely';
  requiredAchievementId?: number;
  validFrom: string;
  validTo?: string;
  hidden: boolean;
}


interface Data {
  points: number;
  checkboxes: ScratchwalkCheckbox[];
  codes: ScratchwalkCode[];

  width: number;
  rewards: Record<number, Array<{what: string, amount: number, modulo?: number}>>;
  labels: Record<string, string>;

  achievementList: Achievement[];
  achievements: Record<number, string[]>;
}

@Component({
    selector: 'app-scratchwalk-rewards',
    templateUrl: './scratchwalk-rewards.component.html',
    styleUrls: ['./scratchwalk-rewards.component.scss']
})
export class ScratchwalkRewardsComponent {
  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
  ) {
    this.loadMyHistory();
  }

  loading = 0;
  data$ = new Subject<Data>();

  private async loadMyHistory() {
    this.loading++;
    try {
      let response = await this.userService.fetchWithToken<Data>("GET", "https://api-v1.scratchwars.zone/scratchwalk-my-rewards.php");
      this.data$.next(response);
    } catch (e) {
      console.error("FPE", e);
      alert("Nepovedlo se načíst historii tvých odměn :(");
    } finally {
      this.loading--;
    }
  }

  generateArray(data: Data) {
    let length = Math.max(Math.ceil((data.points + 5) / data.width / 3), 1) * data.width * 3;
    let out = [];
    for (let i = 1; i <= length; i++) out.push(i);
    return out;
  }

  pointHasReward(point: number, data: Data) {
    return Object.keys(data.rewards).map(Number).includes(point % data.width || data.width);
  }

  getRewardsAsString(point: number, data: Data) {
    return data.rewards[point % data.width || data.width]
      .filter(r => !r.modulo || (Math.ceil(point / data.width) % r.modulo === 0))
      .map(r => `${r.amount > 1 ? `${r.amount}${r.what !== "gold" ? '×' : ''} ` : ''} ${data.labels[r.what]}`)
      .join(' + ');
  }

  getRewardString(point: number, data: Data) {
    return data.rewards[point % data.width || data.width]
      .filter(r => !r.modulo || (Math.ceil(point / data.width) % r.modulo === 0))
      .map(r => `${r.amount > 1 ? `${r.amount}${r.what !== "gold" ? '×' : ''} ` : ''} ${data.labels[r.what]}`)
      .join(' + ');
  }

  pointHasCodes(point: number, data: Data) {
    return data.codes.some(c => c.point === point);
  }

  pointGetCodes(point: number, data: Data) {
    return data.codes.filter(c => c.point === point);
  }

  moment(string: string) {
    return moment(string);
  }

  reasonMap = REASON_MAP;

  abs(input: number): number {
    return Math.abs(input);
  }

  show: number = Number(this.route.snapshot.queryParamMap.get("point"));

  filterAchievements({achievementList, achievements}: Data) {
    return achievementList.filter(
      a => achievements[a.achievementId]?.length > 0 || (
        !a.hidden
          // && (!a.requiredAchievementId || achievements[a.requiredAchievementId]?.length > 0)
          && moment().isSameOrAfter(a.validFrom, "date")
          && (!a.validTo || moment().isSameOrBefore(a.validTo, "date"))
      )
    );
  }

  findAchievement(data: Data, achievementId: Achievement["achievementId"]) {
    return data.achievementList.find(a => a.achievementId === achievementId);
  }

  getCanAcquire(data: Data, achievement: Achievement) {
    let acquiredCount = data.achievements[achievement.achievementId]?.length || 0;
    if (achievement.hidden) return false;
    if (achievement.repeating === "indefinitely") return "yes";
    if (achievement.repeating === "never" && acquiredCount > 0) return false;
    if (achievement.repeating === "never" && acquiredCount === 0) return "yes";
    if (achievement.validTo && moment().isAfter(achievement.validTo, "date")) return "no";
    let canAcquireCount = Math.max(0, Math.ceil((data.points + 1) / data.width))
    if (acquiredCount < canAcquireCount) return "yes";
    return data.width * acquiredCount - data.points;
  }
}
