import { Component } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UserService } from '../../shared/services/user.service';

import { MyData } from '../profile/profile.component';

@Component({
  selector: 'app-golds-transfer',
  templateUrl: './golds-transfer.component.html',
  styleUrls: ['./golds-transfer.component.scss']
})
export class GoldsTransferComponent {
  constructor(
    private router: Router,
    private userService: UserService,
  ) {
    this.loadMyData();
  }

  loading = 0;
  data$ = new Subject<MyData>();

  private async loadMyData() {
    this.loading++;
    try {
      let response = await this.userService.fetchWithToken<MyData>("GET", "https://api-v1.scratchwars.zone/my-data.php");
      this.data$.next(response);
    } catch (e) {
      console.error("FPE", e);
      alert("Nepovedlo se načíst tvá data :(");
    } finally {
      this.loading--;
    }
  }

  amount = 0;
  appId = "";

  async send() {
    this.appId = this.appId.trim().toLowerCase();
    if (!/^[a-zA-Z0-9-]+-\d{4}$/.test(this.appId)) {
      return alert("To nevypadá jako správné app ID :( Zkus ho opravit!");
    }
    if (!confirm(`Radši si ještě jednou zkontroluj, že je App ID správné.\nOpravdu chceš poslat ${this.amount} zlaťáků hráči/hráčce "${this.appId}"?`)) return;
    this.loading++;
    try {
      let id = await this.userService.fetchWithToken<void>("POST", "https://api-v1.scratchwars.zone/golds-transfer.php", {
        amount: this.amount,
        targetAppId: this.appId,
      });
      alert("Poslání zlaťáků proběhlo v pořádku, záznam o odeslání najdeš v historii transakcí 👍");
      this.amount = 0;
      this.appId = "";
      this.loadMyData();
    } catch (e: any) {
      console.error("GTE", e);
      if (e.status === 409) {
        alert("Nemůžeš posílat zlaťáky sám/sama sobě :)");
      } else {
        alert("Nepovedlo se poslat zlaťáky :(");
      }
    } finally {
      this.loading--;
    }
  }
}
