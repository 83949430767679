import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Tournament } from 'app/new/types/tournament.type';
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { UserService } from '../../shared/services/user.service';

import { GET_COLOR, TYPE2_MAP, GAME_MAP, PLACE_MAP } from '../list/list.component';

export interface MyData {
  swoId?: number;
  swoName?: string;
  discordId?: string;
  golds?: {
    total: number;
  };

  worlds: {
    show: boolean;
    nominations: number;
    nominationsLeft: number;
  }
}

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  constructor(
    private router: Router,
    private userService: UserService,
  ) {
    this.loadMyTournaments();
    this.loadMyData();
  }

  logout() {
    this.userService.logout();
    this.router.navigate(["/"]);
  }


  loading = 0;
  data$ = new BehaviorSubject<Array<Tournament & {playerStatus: "registered" | "finished" | "organizer"}>>([]);
  filteredData$ = this.data$;//.pipe(map(data => [...data, ...data, ...data, ...data]));

  myData$ = new Subject<MyData>();

  private async loadMyTournaments() {
    this.loading++;
    try {
      let response = await this.userService.fetchWithToken<Array<Tournament & {playerStatus: "registered" | "finished" | "organizer"}>>("GET", "https://api-v1.scratchwars.zone/my-tournaments.php");
      this.data$.next(response);
    } catch (e) {
      console.error("FPE", e);
      alert("Nepovedlo se načíst tvé akce :(");
    } finally {
      this.loading--;
    }
  }

  private async loadMyData() {
    this.loading++;
    try {
      let response = await this.userService.fetchWithToken<MyData>("GET", "https://api-v1.scratchwars.zone/my-data.php");
      this.myData$.next(response);
    } catch (e) {
      console.error("FPE", e);
      alert("Nepovedlo se načíst tvá data :(");
    } finally {
      this.loading--;
    }
  }

  momentDate(item: Tournament) {
    return moment(item.date);
  }
  momentTime(item: Tournament) {
    return moment(item.time, ["HH:mm"]);
  }


  getColor = GET_COLOR;
  type2Map = TYPE2_MAP;
  gameMap = GAME_MAP;
  placeMap = PLACE_MAP;

  swoId = "";

  async swoConnect() {
    this.loading++;
    try {
      await this.userService.fetchWithToken<void>("POST", "https://api-v1.scratchwars.zone/swo-connect.php", {
        swoId: this.swoId,
      });
      this.loadMyData();
    } catch (e: any) {
      console.error("SCE", e);
      if (e.status === 404) {
        alert("Tento SWO uživatel není spojený s tvým App ID :(");
      } else {
        alert("Nepovedlo se připojit SWO :(");
      }
    } finally {
      this.loading--;
    }
  }

  async swoDisconnect() {
    this.loading++;
    try {
      await this.userService.fetchWithToken<void>("POST", "https://api-v1.scratchwars.zone/swo-disconnect.php");
      this.loadMyData();
    } catch (e) {
      console.error("SDE", e);
      alert("Nepovedlo se odpojit SWO :(");
    } finally {
      this.loading--;
    }
  }

  async discordDisconnect() {
    this.loading++;
    try {
      await this.userService.fetchWithToken<void>("POST", "https://api-v1.scratchwars.zone/discord-disconnect.php");
      this.loadMyData();
    } catch (e) {
      console.error("DDE", e);
      alert("Nepovedlo se odpojit Discord :(");
    } finally {
      this.loading--;
    }
  }

  abs(input: number): number {
    return Math.abs(input);
  }
}
