import { Component } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UserService } from '../../shared/services/user.service';

import { MyData } from '../profile/profile.component';

@Component({
  selector: 'app-golds-payout',
  templateUrl: './golds-payout.component.html',
  styleUrls: ['./golds-payout.component.scss']
})
export class GoldsPayoutComponent {
  constructor(
    private router: Router,
    private userService: UserService,
  ) {
    this.loadAvailableCodes();
    this.loadMyData();
  }

  loading = 0;
  private availableData$ = new Subject<Array<{golds: number, available: number}>>();
  private myData$ = new Subject<MyData>();

  data$ = combineLatest([this.myData$, this.availableData$]).pipe(map(([myData, availableData]) => ({myData, availableData})));

  private async loadAvailableCodes() {
    this.loading++;
    try {
      let response = await this.userService.fetchWithToken<Array<{golds: number, available: number}>>("GET", "https://api-v1.scratchwars.zone/golds-available.php");
      this.availableData$.next(response);
    } catch (e) {
      console.error("FPE", e);
      alert("Nepovedlo se načíst dostupné zlaťákové QR kódy :(");
    } finally {
      this.loading--;
    }
  }

  private async loadMyData() {
    this.loading++;
    try {
      let response = await this.userService.fetchWithToken<MyData>("GET", "https://api-v1.scratchwars.zone/my-data.php");
      this.myData$.next(response);
    } catch (e) {
      console.error("FPE", e);
      alert("Nepovedlo se načíst tvá data :(");
    } finally {
      this.loading--;
    }
  }

  counts: Record<number, number> = {};

  private countDefaultLoader = this.data$
    .pipe(take(1))
    .subscribe(({myData: {golds}, availableData}) => {
      availableData = availableData.filter(({golds}) => golds < 10 || golds % 10 === 0);
      let remaining = golds ? golds.total : 0;

      for (let {golds, available} of availableData) {
        let count = Math.min(available, Math.floor(remaining / golds));
        if (count > 0) {
          this.plus(golds, count);
          remaining -= golds * count;
        }
      }
    });

  currentCount(golds: number) {
    return this.counts[golds] || 0;
  }
  plus(golds: number, count = 1) {
    this.counts[golds] = this.counts[golds] || 0;
    this.counts[golds] += count;
  }
  minus(golds: number) {
    this.counts[golds] = this.counts[golds] || 0;
    this.counts[golds]--;
    if (this.counts[golds] === 0) delete this.counts[golds];
  }
  total() {
    return Object.keys(this.counts).map(Number).reduce((acc, golds) => acc + (golds * this.counts[golds]), 0);
  }
  isPlusDisabled(golds: number, haveTotal: number, available: number) {
    return this.currentCount(golds) >= available || this.total() + golds > haveTotal;
  }
  isMinusDisabled(golds: number) {
    return this.currentCount(golds) === 0;
  }

  async payout() {
    this.loading++;
    try {
      let id = await this.userService.fetchWithToken<void>("POST", "https://api-v1.scratchwars.zone/golds-payout.php", this.counts);
      alert("Vyplacení zlaťáků proběhlo v pořádku, svoje QR kódy najdeš v historii transakcí 👍");
      this.router.navigate(['/historie-zlataku'], {queryParams: {id}});
    } catch (e) {
      console.error("POE", e);
      alert("Nepovedlo se vyplatit zlaťáky :(");
    } finally {
      this.loading--;
    }
  }
}
