import { Component, Input } from '@angular/core';
import * as moment from 'moment';
import { Tournament } from '../../new/types/tournament.type';

@Component({
  selector: 'app-monthly-restriction',
  templateUrl: './monthly-restriction.component.html',
  styleUrls: ['./monthly-restriction.component.css']
})
export class MonthlyRestrictionComponent {
  @Input() set tournament(tournament: Tournament) {
    this.tournamentIn = tournament;
    this.monthFromTournament = moment(tournament.date).format('YYYY-MM');
  }
  monthFromTournament?: string;
  @Input() month?: string;
  tournamentIn?: Tournament;
}
