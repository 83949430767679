import { Component, Input } from '@angular/core';
import * as moment from 'moment';
import { Tournament } from '../../new/types/tournament.type';

@Component({
  selector: 'app-seasonal-restriction',
  templateUrl: './seasonal-restriction.component.html',
  styleUrls: ['./seasonal-restriction.component.css']
})
export class SeasonalRestrictionComponent {
  @Input() set tournament(tournament: Tournament | undefined) {
    this.tournamentIn = tournament;
    this.date = tournament?.date || '';
    if (tournament?.type2 === 'worlds') this.date = moment(tournament.date).subtract(1, 'month').format('YYYY-MM-DD');
  }
  get tournament() {
    return this.tournamentIn;
  }
  date: string = '';
  tournamentIn?: Tournament;
}
