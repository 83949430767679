import { Component, Input } from '@angular/core';
import { OriginalPrize, PatchedPrize, SortedPrize, SortedRange, UnsortedRange } from 'app/new/types/prize.type';

@Component({
  selector: 'app-prizes',
  templateUrl: './prizes.component.html',
  styleUrls: ['./prizes.component.css']
})
export class PrizesComponent {
  Infinity = Infinity;
  selectedIndex = 0;

  @Input() set prizes(rawPrizes: OriginalPrize[]) {
    for (let prize of rawPrizes) {
      prize.minPlayers = prize.minPlayers ?? 1;
      prize.maxPlayers = prize.maxPlayers ?? Infinity;
    }

    let patchedPrizes = rawPrizes as PatchedPrize[];

    let ranges: UnsortedRange[] = [{from: 1, to: Infinity, prizes: []}];
    for (let {minPlayers} of patchedPrizes) {
      let rangeSplit = ranges.find(({from, to}) => minPlayers > from && minPlayers <= to);
      if (rangeSplit) {
        let leftRange = {from: rangeSplit.from, to: minPlayers - 1, prizes: []};
        let rightRange = {from: minPlayers, to: rangeSplit.to, prizes: []};
        let newRanges = [];
        for (let range of ranges) {
          if (range === rangeSplit) {
            newRanges.push(leftRange);
            newRanges.push(rightRange);
          } else {
            newRanges.push(range);
          }
        }
        ranges = newRanges;
      }
    }

    for (let {maxPlayers} of patchedPrizes) {
      let rangeSplit = ranges.find(({from, to}) => maxPlayers >= from && maxPlayers < to);
      if (rangeSplit) {
        let leftRange = {from: rangeSplit.from, to: maxPlayers, prizes: []};
        let rightRange = {from: maxPlayers + 1, to: rangeSplit.to, prizes: []};
        let newRanges = [];
        for (let range of ranges) {
          if (range === rangeSplit) {
            newRanges.push(leftRange);
            newRanges.push(rightRange);
          } else {
            newRanges.push(range);
          }
        }
        ranges = newRanges;
      }
    }

    for (let {minPlayers, maxPlayers, ...prize} of patchedPrizes) {
      ranges.filter(({from, to}) => from >= minPlayers && to <= maxPlayers).forEach(range => range.prizes.push(prize))
    }

    let sortedRanges = ranges.map(({from, to, prizes}) => {
      let range: SortedRange = {from, to};
      for (let {what, ...prize} of prizes) {
        if (what === "place") {
          range.place = range.place ?? [];
          range.place.push(prize);
          range.place.sort((a, b) => (a.place ?? a.placeFrom ?? 0) - (b.place ?? b.placeFrom ?? 0))
        } else {
          if (prize.override || !range[what]?.override) range[what] = prize;
        }
      }
      if (range.place?.some(p => p.override)) {
        range.place = range.place.filter(p => p.override);
      }
      return range;
    }).sort((a, b) => b.from - a.from);

    console.log(sortedRanges)
    this.ranges = sortedRanges;
  }
  ranges: SortedRange[] = [];
  get r() {
    return this.ranges[this.selectedIndex];
  }

  @Input() isAuthorized?: boolean;

  getPrizeString(p: SortedPrize) {
    return [
      p.text,
      p.golds && (`${p.golds} zlaťák${p.golds === 1 ? "" : p.golds >= 2 && p.golds <= 4 ? "y" : "ů"}`),
      p.scratchwalkPoints && (`${p.scratchwalkPoints} ScratchWalk bod${p.scratchwalkPoints === 1 ? "" : p.scratchwalkPoints >= 2 && p.scratchwalkPoints <= 4 ? "y" : "ů"}`),
      p.nominations && (p.nominations === 1 ? `nominace` : `${p.nominations} nominac${p.nominations >= 1 && p.nominations <= 4 ? "e" : "í"}`) + ` na Mistrovství světa ve Scratch Wars`,
    ].filter(t => t !== undefined).join(" + ");
  }
}
