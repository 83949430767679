import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { ComponentsComponent } from './components/components.component';
import { ProfileComponent } from './examples/profile/profile.component';
import { SignupComponent } from './examples/signup/signup.component';
import { LandingComponent } from './examples/landing/landing.component';
import { NucleoiconsComponent } from './components/nucleoicons/nucleoicons.component';
import { ListComponent } from './examples/list/list.component';
import { TournamentComponent } from './examples/tournament/tournament.component';
import { GoldsTransactionsComponent } from './examples/golds-transactions/golds-transactions.component';
import { GoldsPayoutComponent } from './examples/golds-payout/golds-payout.component';
import { GoldsTransferComponent } from './examples/golds-transfer/golds-transfer.component';
import { ScratchwalkRewardsComponent } from './examples/scratchwalk-rewards/scratchwalk-rewards.component';

const routes: Routes = [
    { path: '', pathMatch: 'full', component: ListComponent },
    { path: 'home',                component: ComponentsComponent },
    { path: 'profil',              component: ProfileComponent },
    { path: 'prihlaseni',          component: SignupComponent },
    // { path: 'landing',             component: LandingComponent },
    // { path: 'nucleoicons',         component: NucleoiconsComponent },
    
    { path: 'turnaj/:id',          component: TournamentComponent },

    { path: 'historie-zlataku',          component: GoldsTransactionsComponent },
    { path: 'vyplaceni-zlataku',          component: GoldsPayoutComponent },
    { path: 'poslani-zlataku',          component: GoldsTransferComponent },
    { path: 'scratchwalk',          component: ScratchwalkRewardsComponent },

    { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      // useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
