import { Component } from '@angular/core';
import { UserService } from 'app/shared/services/user.service';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { Tournament } from '../../new/types/tournament.type';

export const GET_COLOR = (item: Tournament) => {
  switch (true) {
    case item.type2 === "league" && item.place === "online" && item.game === "app":
      return "league-online-app";
    case item.type2 === "league" && item.place === "online" && item.game === "swo":
      return "league-online-swo";
    case item.type2 === "league" && item.place === "onsite":
      return "league-onsite";
    case item.type2 === "og" && item.place === "online" && item.game === "app":
      return "og-online-app";
    case item.type2 === "og" && item.place === "online" && item.game === "swo":
      return "og-online-swo";
    case (item.type2 === "og" || item.type2 === "og+orgplay") && item.place === "onsite":
      return "og-onsite";
    case item.type2 === "event" || item.type2 === "draft":
      return "event";
    case item.type2 === "stream":
      return "stream";
    case item.type2 === "orgplay":
      return "orgplay";
    default:
      return "normal";
  }
};

export const TYPE2_MAP = {
  og: "Setkání s\u00a0OG",
  league: "Ligový turnaj",
  normal: "Běžný turnaj",
  draft: "Draft turnaj",
  event: "Akce arény",
  orgplay: "Organizované hraní",
  stream: "Stream",
  worlds: "Mistrovství světa",
  major: "Major turnaj",
};

export const GAME_MAP = {
  paper: "papírové karty",
  app: "appka",
  swo: "SWO",
};

export const PLACE_MAP = {
  online: "online",
  onsite: "v\u00a0aréně",
};

export const REASON_MAP = {
  "attendance": "Za účast",
  "best-newcomer": "Pro nejlepšího nováčka",
  "organizer": "Za organizování",
  "place-1": "Za 1. místo",
  "place-2": "Za 2. místo",
  "place-3": "Za 3. místo",
  "place-4": "Za 4. místo",
  "place-5": "Za 5. místo",
  "place-6": "Za 6. místo",
  "place-7": "Za 7. místo",
  "place-8": "Za 8. místo",
  "place-9": "Za 9. místo",
  "place-10": "Za 10. místo",
  "place-11": "Za 11. místo",
  "place-12": "Za 12. místo",
  "place-13": "Za 13. místo",
  "place-14": "Za 14. místo",
  "place-15": "Za 15. místo",
  "place-16": "Za 16. místo",
  "og-defeated": "Za poražení overgamera/overgamerky",
  
  "transfer": "Převod mezi hráči",
  "payout": "Vyplacení do QR kódů",

  "scratchwalk": "Za ScratchWalk",
  "reward": "Za odměnu",
  "bonus": "Za bonus",
  "giveaway": "Za giveaway",
};

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent {
  constructor(
    private userService: UserService,
  ) {
    this.load();
  }

  loading = false;
  data$ = new BehaviorSubject<Tournament[]>([]);
  filteredData$ = this.data$;//.pipe(map(data => [...data, ...data, ...data, ...data]));

  private async load() {
    this.loading = true;
    try {
      let response = await this.userService.fetchWithToken<Tournament[]>("GET", "https://api-v1.scratchwars.zone/tournaments.php");
      this.data$.next(response);
    } catch (e) {
      console.error("FFD", e);
      alert("Nepovedlo se načíst seznam turnajů :(");
    } finally {
      this.loading = false;
    }
  }

  momentDate(item: Tournament) {
    return moment(item.date);
  }
  momentTime(item: Tournament) {
    return moment(item.time, ["HH:mm"]);
  }

  /*
  "og-online-app": #a7b,
  "og-online-swo": #b7a,
  "og-onsite": #7bb,
  "league-online-app": #68f,
  "league-online-swo": #86f,
  "league-onsite": #bb7,
  */

  getColor = GET_COLOR;
  type2Map = TYPE2_MAP;
  gameMap = GAME_MAP;
  placeMap = PLACE_MAP;
}
