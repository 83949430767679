import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Tournament, TournamentId } from 'app/new/types/tournament.type';
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { UserService } from '../../shared/services/user.service';

import { GET_COLOR, TYPE2_MAP, GAME_MAP, PLACE_MAP, REASON_MAP } from '../list/list.component';

interface Transaction {
  transactionId: number;
  amount: number;
  reason: string;
  tournamentId?: TournamentId,
  otherAppId?: string;
  additionalInfo?: string;
  timestamp: string;
}

interface GoldCode {
  transactionId: number;
  code: string;
  golds: number;
  validUntil: string;
}

@Component({
    selector: 'app-golds-transactions',
    templateUrl: './golds-transactions.component.html',
    styleUrls: ['./golds-transactions.component.scss']
})
export class GoldsTransactionsComponent {
  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
  ) {
    this.loadMyTransactions();
  }

  loading = 0;
  data$ = new Subject<{transactions: Transaction[], codes: GoldCode[]}>();

  private async loadMyTransactions() {
    this.loading++;
    try {
      let response = await this.userService.fetchWithToken<{transactions: Transaction[], codes: GoldCode[]}>("GET", "https://api-v1.scratchwars.zone/golds-transactions.php");
      this.data$.next(response);
    } catch (e) {
      console.error("FPE", e);
      alert("Nepovedlo se načíst historii tvých transakcí :(");
    } finally {
      this.loading--;
    }
  }

  moment(string: string) {
    return moment(string);
  }

  reasonMap = REASON_MAP;

  abs(input: number): number {
    return Math.abs(input);
  }

  show: number = Number(this.route.snapshot.queryParamMap.get("id"));

  filterCodes(codes: GoldCode[], transactionId: number) {
    return codes.filter(c => c.transactionId === transactionId);
  }
}
